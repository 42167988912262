var stakingNFT_abi = require("./ABI/staking.json");
var nft_gen1 = require("./ABI/nftgen1.json");
var nft_gen2 = require("./ABI/nftgen2.json");
var nft_gen3 = require("./ABI/nftgen3.json");

export const config = {
    chainId: 25, //Fuji testnet : 43113, mainnet : 43114.  bsctestnet : 97, Rikeby: 4
    mainNetUrl: 'https://evm.cronos.org',

    StakingAddress: '0xA2DB9B2ca251398d8A69BB238F8b7292d81f6f8B',
    StakingAbi: stakingNFT_abi,
    nftAddresses: ['0x95603A09FE2D2aFDD7CE5d0E3cD83319666E62d9', 
                   '0x5ac04d6f18386ad2b556d16c2cB99F0d9f7564CB', 
                   '0xfBf2727Bef4F6C4749948bed4aB865c448470898'],
    nftAbis: [nft_gen1, nft_gen2, nft_gen3],
    INFURA_ID: 'e6943dcb5b0f495eb96a1c34e0d1493e'
}